import gqt from "graphql-tag";
import { extendSchemaImpl } from "graphql/utilities/extendSchema";

export const GetGenresQuery = gqt`
  query GetGenres {
    genre(order_by: { genre: asc }) {
      genre
    }
  }
`;
export const GetConfigQuery = gqt`query MyQuery {
  config_by_pk(for: "user") {
    privacy_policy
    support_details
    terms_condition
  }
}`;
//user
export const SearchUserQuery = gqt`query MyQuery($limit: Int = 10, $search: String ) {
  user(limit: $limit, where: {handle_name: {_ilike: $search}, is_artist: {_eq: true}, is_admin: {_eq: false}}) {
    profile_picture
    id
    handle_name
    full_name
  }
}
`;


//user serach now added
export const SearchOnlyUserQuery = gqt`query MyQuery($search: String ) {
  user( where: {handle_name: {_ilike: $search}, is_artist: {_eq: false}, is_admin: {_eq: false}}) {
    profile_picture
    id
    handle_name
    full_name
  }
}
`;

export const CheckUserBlockSubscription = gqt`subscription MySubscription($id: String!) {
  user_by_pk(id: $id) {
    suspend
  }
}`;
export const GetTrendingArtistQuery = gqt`query MyQuery($user: String) {
  user(limit: 5, order_by: {followers_aggregate: {count: desc_nulls_last}}, where: {is_artist: {_eq: true}, _and: {full_name: {_is_null: false}, handle_name: {_is_null: false}, id: {_neq: $user}}}) {
    full_name
    handle_name
    id
    profile_picture
    followers(where: {follower_id: {_eq: $user}}) {
      id
    }
    followers_aggregate(where: {follower_id: {_eq: $user}}) {
      aggregate {
        count
      }
    }
  }
}


`;

export const GetRecomendedArtist = gqt`query MyQuery($_has_keys_any: [String!] = "", $limit: Int = 5, $offset: Int = 0, $user: String = "") {
  user(where: {genre: {_has_keys_any: $_has_keys_any}, id: {_neq: $user}}, offset: $offset, limit: $limit) {
    handle_name
    full_name
    id
    followers(where: {follower_id: {_eq: $user}}) {
      id
    }
    profile_picture
    followers_aggregate(where: {follower_id: {_eq: $user}}) {
      aggregate {
        count
      }
    }
  }
}
`;

export const UpdateUserMutation = gqt`mutation MyMutation($id: String!, $email: String!, $bio: String!, $full_name: String!, $phone_number: String!, $profile_picture: String!, $genre: jsonb!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {bio: $bio, email: $email, full_name: $full_name, genre: $genre, phone_number: $phone_number, profile_picture: $profile_picture}) {
    id
    bio
    email
    full_name
    genre
    handle_name
    id
    phone_number
    profile_picture
  }
}
`;

export const UpdateProfileMutation = gqt`mutation MyMutation($_set: user_set_input!, $id: String! ) {
  update_user_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}`;
export const GetProfileDataQuery = gqt`query MyQuery($id: String!) {
  user_by_pk(id: $id) {
    bio
    email
    full_name
    genre
    handle_name
    id
    phone_number
    profile_picture
    audio_quality
    cover_photo
    user_batches(order_by: {created_at: asc}) {
      batch {
        name
        icon
      }
    }
    followers_aggregate {
      aggregate {
        count
      }
    }
    tracks_aggregate(where: {artist_id: {_eq: $id}}) {
      aggregate {
        count
      }
    }
    albums_aggregate(where: {artist_id: {_eq: $id}}) {
      aggregate {
        count
      }
    }
  }
}

`;

export const SearchHandleNameQuery = gqt`query MyQuery($search: String!) {
  user_public(where: {handle_name: {_eq: $search}}) {
    handle_name
    id
  }
}
`;

export const CheckFollowQuery = gqt`query MyQuery($followee_id: String,$follower_id:String ) {
  follow(where: {followee_id: {_eq: $followee_id}, follower_id: {_eq: $follower_id}}) {
    id
  }
}
`;
export const GetFollowersCountQuery = gqt`query GetFollowersCount($id: String!) {
  user_by_pk(id: $id) { 
    followers_aggregate{
      aggregate{
        count
      }
    }
}
}
`;
export const InsertFollowMutation = gqt`mutation MyMutation( $followee_id: String ) {
  insert_follow_one(object: { followee_id: $followee_id}) {
    id
  }
}
`;
export const DeleteFollowMutation = gqt`mutation MyMutation($id: uuid!) {
  delete_follow_by_pk(id: $id) {
    id
  }
}`;

//track
export const CheckTrackLikeQuery = gqt`query MyQuery($track_id: uuid , $id: String ) {
  like(where: {track_id: {_eq: $track_id}, user_id: {_eq: $id}}) {
    id
  }
}
`;
export const InsertLikeMutation = gqt`mutation MyMutation($track_id: uuid!) {
  insert_like_one(object: {track_id: $track_id}) {
    id
  }
}
`;
export const DeleteLikeMutation = gqt`mutation MyMutation($id: uuid!) {
  delete_like_by_pk(id: $id) {
    id
  }
}`;
export const TrendingTracksQuery = gqt`query TrendingTracks {
  random_tracks(limit: 5, order_by: {listens_aggregate: {count: desc_nulls_last}}) {
    id
    title
    thumbnail
    url
    user{handle_name}

  }
}
`;

export const TrackSortQuery = gqt`query MyQuery($limit: Int, $where: track_bool_exp, $order_by: [track_order_by!]={created_at: asc}) {
  track(limit: $limit, where: $where, order_by: $order_by) {
    id
    title
    thumbnail
    meta_data
    url
    lyrics
    likes_aggregate {
      aggregate { 
        count
      }
    }
    user {
      id
      handle_name
    }
  }
}

`;

export const GetTracksQuery = gqt`query MyQuery($limit: Int,  $search: String) {
  track( limit: $limit, where: {_and: { title: {_ilike: $search}}}, order_by: {created_at: asc}) {
    likes_aggregate {
      aggregate {
        count
      }
    }
    listens_aggregate {
      aggregate {
        count
      }
    }
    title
    thumbnail
    lyrics
    url
    id
    user{
      handle_name
    }
  }
 
}

`;

export const GetTrackByPkQuery = gqt`query MyQuery($id: uuid!) {
  track_by_pk(id: $id) {
    lyrics
    description
    genre
    is_private
    id
    meta_data
    tags
    thumbnail
    title
    url
    likes_aggregate {
      aggregate { 
        count
      }
    }
    user {
      id
      handle_name
    }
  }
}
`;

//reviews
export const ListReviewQuery = gqt`query MyQuery($track_id: uuid, $user_id: String!) {
  review(where: {track_id: {_eq: $track_id}, user_id: {_eq:$user_id}}) {
    rate
  }
}`;
export const InsertReviewMutation = gqt`mutation MyMutation($rate: Int , $track_id: uuid! ) {
  insert_review_one(object: {rate: $rate, track_id: $track_id}) {
    id
  }
}`;
//comments
export const insertCommentMutation = gqt`mutation MyMutation($object: comment_insert_input = {}) {
  insert_comment_one(object: $object) {
    comment
    id
  }
}
`;
export const ListCommentsQuery = gqt`query MyQuery($track_id: uuid) {
  comment(where: {track_id: {_eq: $track_id}, parent_comment_id: {_is_null: true}}) {
    comment
    created_at
    id
    user {
      id
      full_name
      handle_name
    }
    comment_likes_aggregate {
      aggregate {
        count
      }
    }
    comments_aggregate {
      aggregate {
        count
      }
    }
  }
  comment_aggregate(where: {track_id: {_eq: $track_id}, parent_comment_id: {_is_null: true}}){
    aggregate{
      count
    }
  }
}
`;
export const CommentByPkQuery = gqt`query MyQuery($id: uuid! , $user_id: String ) {
  comment_by_pk(id: $id) {
    comment_likes_aggregate {
      aggregate {
        count
      }
    }
    comment_likes(where: {user_id: {_eq: $user_id}}) {
      id
    }
    comments_aggregate {
      aggregate {
        count
      }
    }
    comments {
      comment
      created_at
      id
      comment_likes_aggregate {
        aggregate {
          count
        }
      }
      comment_likes(where: {user_id: {_eq: $user_id}}) {
      id
    }
      user {
        id
        handle_name
        full_name
      }
      
    }
  }
}
`;
export const LikeCommentMutation = gqt`
mutation MyMutation($comment_id: uuid ) {
  insert_comment_like_one(object: {comment_id: $comment_id}) {
    id
  }
}`;
//album
export const GetArtistAlbumQuery = gqt`query MyQuery($limit: Int ,$artist_id:String) {
  album(limit: $limit, where: {artist_id: {_eq: $artist_id}}) {
    id
    thumbnail
    title
    album_tracks_aggregate {
      aggregate {
        count
      }
    }
    user {
      handle_name
    }
  }
}`;
export const GetAlbumQuery = gqt`query MyQuery( $search: String, $limit: Int ) {
  album(where: {_and: { title: {_ilike: $search}}}, order_by: {created_at: asc}, limit: $limit) {
    is_private
    id
    thumbnail
    title
    updated_at
    album_tracks_aggregate {
      aggregate {
        count
      }
    }
  }
  album_aggregate(where: {_and: { title: {_ilike: $search}}}) {
    aggregate {
      count
    }
  }
}

`;
export const GetAlbumData = gqt`query MyQuery($id: uuid!) {
  album_by_pk(id: $id) {
    title
    thumbnail
    id
    description
    album_tracks_aggregate {
      aggregate {
        count
      }
    }
    album_tracks {
      track {
        id
        thumbnail
        title
        url
        lyrics
        meta_data
        likes_aggregate {
          aggregate { 
            count
          }
        }
        user {
          id
          handle_name
        }
      }
    }
  }
}


`;

//album_track

export const DeleteAlbumTrackMutation = gqt`mutation MyMutation($album_id: uuid!, $track_id: uuid!) {
  delete_album_track(where: {album_id: {_eq: $album_id}, track_id: {_eq: $track_id}}) {
    affected_rows
  }
}
`;

//stats
export const GetDashboardStats = gqt`subscription MyQuery($id: String) {
  listen_aggregate(where: {track: {artist_id: {_eq: $id}}}) {
    aggregate {
      count
      sum {
        listened_time
      }
    }
  }
}

`;
export const GetReviewsCountSubscription = gqt`subscription MyQuery($id: String) {
  review_aggregate(where: {track: {artist_id: {_eq: $id}}})  {
   aggregate {
     count
   }
 }
 }`;

//library
//downloads
export const GetDownloadsCountQuery = gqt`query MyQuery($id: String ) {
  download_aggregate(where: {user_id: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}`;
//playlists
export const GetAllPlaylistsQuery = gqt`query GetAllPlaylistsQuery($id: String ) {
  playlist(where: {user_id: {_eq: $id}}) {
    playlist_tracks_aggregate {
      aggregate {
        count
      }
    }
    id
    thumbnail
    title
  }
}
`;
export const InsertPlaylistMutation = gqt`mutation MyMutation($thumbnail: String , $title: String) {
  insert_playlist_one(object: {thumbnail: $thumbnail, title: $title}) {
    id
  }
}
`;

export const GetPlaylistByPk = gqt`query MyQuery($id: uuid! ) {
  playlist_by_pk(id: $id) {
    playlist_tracks_aggregate {
      aggregate {
        count
      }
    }
    thumbnail
    title
    id
    playlist_tracks {
      id
      track {
        thumbnail
        id
        lyrics
        title
        meta_data
        url
        likes_aggregate {
          aggregate { 
            count
          }
        }
        user {
          id
          handle_name
        }
      }
    }
  }
}`;

export const GetSongsToAddQuery = gqt`query MyQuery($playlist_id: uuid!, $_ilike: String ) {
  track(where: {_and: { title: {_ilike: $_ilike}, _not: {playlist_tracks: {playlist_id: {_eq: $playlist_id}}}}}) {
    title
    thumbnail
    id
    meta_data
    user{
      full_name
      handle_name
    }
  }
}
`;
export const EditPlaylistMutation = gqt`mutation MyMutation($id: uuid!, $thumbnail: String , $title: String ) {
  update_playlist_by_pk(pk_columns: {id: $id}, _set: {thumbnail: $thumbnail, title: $title}) {
    id
  }
}`;
export const DeletePlaylistMutation = gqt`mutation MyMutation($id: uuid!) {
  delete_playlist_by_pk(id: $id) {
    id
  }
}`;
export const InsertPlaylistTrackMutation = gqt`mutation MyMutation($playlist_id: uuid!, $track_id: uuid!) {
  insert_playlist_track_one(object: {playlist_id: $playlist_id, track_id: $track_id}) {
    playlist_id
    id
    track_id
  }
}
`;
export const DeletePlaylistTrack = gqt`mutation DeletePlaylistTrack($id: uuid!) {
  delete_playlist_track_by_pk(id: $id) {
    id
  }
}
`;
//flag
export const InsertFlagMutation = gqt`mutation MyMutation($track_id: uuid!, $reason: String ) {
  insert_flag_one(object: {track_id: $track_id, reason: $reason}) {
    id
  }
}
`;
//banner
export const GetBannerQuery = gqt`query MyQuery($stop_date: timestamptz) {
  random_banner(limit: 1, where: {stop_date: {_gte: $stop_date}, start_date: {_lte: $stop_date}, publish: {_eq: true}}) {
    created_at
    id
    updated_at
    url
    description
    link
    name
    publish
    start_date
    stop_date
    vendorName
    desktop_image
    font_color
    show_text
  }
}


`;
//artist report
export const CheckReportQuery = gqt`query MyQuery($artist_id: String, $user_id: String = "") {
  artist_report(where: {artist_id: {_eq: $artist_id}, user_id: {_eq: $user_id}}) {
    id
  }
}`;

export const InsertReportMutation = gqt`mutation InsertReport($artist_id: String , $report_message: String) {
  insert_artist_report_one(object: {artist_id: $artist_id, report_message: $report_message}) {
    id
  }
}
`;

// listen
export const InsertListenMutation = gqt`mutation InsertListen($listened_time: Int, $track_id: uuid!) {
  insert_listen_one(object: {listened_time: $listened_time, track_id: $track_id}) {
    id
  }
}
`;
export const UpdateListenMutation = gqt`mutation UpdateListen($listened_time: Int, $id: uuid!) {
  update_listen_by_pk(_set: {listened_time: $listened_time}, pk_columns: {id: $id}) {
    id
  }
}
`;
//notification
export const GetNotificationQuery = gqt`query MyQuery($id: String ) {
  notification(where: {user_id: {_eq: $id}}, order_by: {created_at: desc}) {
      message
      title
      created_at
      id
  }
}
`;

//new additions
//query to fetch user's playlist
export const UserTrackQuery = gqt`
  query MyQuery($id: String!) {
    user_by_pk(id: $id) {
      playlists {
        playlist_tracks {
          id
          track {
            lyrics
            url
            title
            thumbnail
          }
        }
      }
    }
  }
`;

