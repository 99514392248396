import React, { createContext, useState, useEffect } from "react";
import { HASURA_ENDPOINT_DEV, firebaseEndPoint } from "../config";
import {
  createClient,
  Provider,
  defaultExchanges,
  subscriptionExchange,
  useQuery,
} from "urql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signOut,
} from "firebase/auth";
import { updateLastUSed } from "../functions";
export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: true, data: null });
  const authfirebase = getAuth();

  const subscriptionClient = new SubscriptionClient(
    `wss://${HASURA_ENDPOINT_DEV}`,
    {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${auth?.data?.accessToken}`,
        },
      },
    }
  );
  let headers = {};

  if (auth?.data?.accessToken) {
    headers = {
      Authorization: `Bearer ${auth?.data?.accessToken}`,
    };
  }
  const client = createClient({
    url: `https://${HASURA_ENDPOINT_DEV}`,
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription(operation) {
          return subscriptionClient.request(operation);
        },
      }),
    ],
    requestPolicy: "network-only",
    fetch: fetch,
    fetchOptions: () => {
      if (!auth) {
        return true;
      }
      return {
        headers,
      };
    },
  });

  const setAuthData = (data) => {
    setAuth({ data: data });
  };

  useEffect(() => {
    setAuth({
      loading: false,
      data: JSON.parse(localStorage.getItem("authData")),
    });
  }, []);
  //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.
  const getToken = async () => {
    return onAuthStateChanged(authfirebase, async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        let idTokenResult = await user.getIdTokenResult();
        let hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];
        if (hasuraClaim && hasuraClaim["x-hasura-default-role"] !== "admin") {
          setAuthData({
            id: user?.uid,
            accessToken: user?.stsTokenManager?.accessToken,
            email: user?.email,
            profile_picture: user?.photoURL,
            handle_name: user?.displayName,
          });
          await updateLastUSed(user?.stsTokenManager?.accessToken);
        } else {
          console.log("is an admin account");
          signOut(authfirebase);
          setAuthData(null);
        }
      } else {
        signOut(authfirebase);
        setAuthData(null);
      }
    });
  };
  //This function will be executed every time component is mounted (every time the user refresh the page);
  useEffect(() => {
    getToken();
  }, []);
  setInterval(() => {
    getToken();
  }, 60 * 60 * 1000);
  useEffect(() => {
    localStorage.setItem("authData", JSON.stringify(auth.data));
  }, [auth]);
  // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

  return (
    <AuthContext.Provider value={{ auth, setAuthData }}>
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
