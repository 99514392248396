export default {
  button: {
    primary: {
      base: "text-white bg-fuchsia-800 border border-transparent",
      active:
        "active:bg-fuchsia-800 hover:bg-fuchsia-700 focus:ring focus:ring-fuchsia-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
  },
  // Input
  input: {
    checkbox:
      "text-fuchsia-600 form-checkbox focus:border-fuchsia-400 focus:outline-none focus:ring focus:ring-fuchsia-300 focus:ring-offset-0 rounded dark:focus:ring-gray-300",
  },
  // Backdrop
  backdrop: {
    base: "fixed  py-10 inset-0 z-40 flex items-center bg-black bg-opacity-75 sm:justify-center overflow-y-auto",
  },
};
