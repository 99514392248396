export const HASURA_ENDPOINT_DEV =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "hasura.musiccoast.com/v1/graphql"
    : "hasura.mccoast.click/v1/graphql";
export const baseEndPoint =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "https://api.musiccoast.com"
    : "https://api.mccoast.click";
export const firebaseEndPoint =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "https://us-central1-music-coast-production.cloudfunctions.net"
    : "https://us-central1-music-coast-69b3b.cloudfunctions.net";
export const AppID = "befae09ad5ed4cc7a4cc6284fa21498b";
export const AppUrl =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "https://app.musiccoast.com"
    : "https://app.mccoast.click";
